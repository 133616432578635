export const fixedDateRange = [
  {
    id: 1,
    duration: "Today",
  },
  {
    id: 2,
    duration: "Yesterday",
  },
  {
    id: 6,
    duration: "This Month",
  },
  {
    id: 7,
    duration: "Last Month",
  },
  {
    id: 9,
    duration: "Custom Date",
  },
  {
    id: 10,
    duration: "Custom Month",
  },
  {
    id: 8,
    duration: "Last Year",
  },
];

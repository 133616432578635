import React from "react";

const CustomerDisplay = () => {
    return(
        <div className="main-content">
            <div className="site-notice" id="notice-center"></div>
            <div id="sales_page_holder">
                <div id="customer_display_container" className="sales clearfix">
                    <div className="text-center">
                        <h1>Thank you for shopping at STUDIO11!</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerDisplay;
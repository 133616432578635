export const leadStatus = [
  {
    id: 1,
    status: "To be Validated",
    className:"color-box",
  },
  {
    id: 2,
    status: "Prospective",
    className:"color-box-2",
  },
  {
    id: 3,
    status: "Hot",
    className:"color-box-3",
  },
  {
    id: 4,
    status: "Converted",
    className:"color-box-4",
  },
  {
    id: 5,
    status: "Cold",
    className:"color-box-5",
  },
  {
    id: 6,
    status: "Call Not Connected",
    className:"color-box-6",
  },
];

export const whatsappTemplates = [
  {
    id: 1272404983119428,
    body: `Hi $(variable1),  Thank you for registering with us. Your ref. no is $(variable2)`,
  },
  {
    id: 3969893143040412,
    body: `Your payment of Rs. $(variable1)  is successful!`,
  },
  {
    id: 315704979694901,
    body: `Your payment of Rs: $(variable1), for $(variable2) is successful! Congrats!`,
  },
  {
    id: 313483239706552,
    body: `Hi $(variable1), This is the payment details for your appointment. Make sure you show the receipt at the center.`,
  },
];

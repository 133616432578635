import { faAccessibleIcon, faBootstrap, faGulp, faHtml5, faReact, faSass } from "@fortawesome/free-brands-svg-icons";
import { faCalendarAlt, faFileAlt, faImages, faMapMarkedAlt, faMobileAlt, faPenFancy } from "@fortawesome/free-solid-svg-icons";

export default [
  {
    "id": 0,
    "title": "React.js",
    "icon": faReact,
    "description": "The most popular front-end library in the world"
  },
  {
    "id": 1,
    "title": "Bootstrap 5",
    "icon": faBootstrap,
    "description": "Built with the most popular CSS Framework"
  },
  {
    "id": 2,
    "title": "Sass",
    "icon": faSass,
    "description": "Variables and mixins to empower development"
  },
  {
    "id": 3,
    "title": "Responsive",
    "icon": faMobileAlt,
    "description": "All pages and components are 100% responsive"
  },
  {
    "id": 4,
    "title": "Gulp",
    "icon": faGulp,
    "description": "Gulp & BrowserSync for a flawless workflow"
  },
  {
    "id": 5,
    "title": "Creative rights",
    "icon": faImages,
    "description": "All images, icons and fonts are licensed & free to use"
  },
  {
    "id": 6,
    "title": "Documentation",
    "icon": faFileAlt,
    "description": "Everything that comes with Rocket is well documented"
  },
  {
    "id": 7,
    "title": "WCAG Accessibility",
    "icon": faAccessibleIcon,
    "description": "Accessibility oriented design and functionality"
  },
  {
    "id": 8,
    "title": "W3C Validated",
    "icon": faHtml5,
    "description": "HTML pages are all valid by W3C Standards"
  },
  {
    "id": 10,
    "title": "Calendar",
    "icon": faCalendarAlt,
    "description": "Advanced integration with FullCalendar.js"
  },
  {
    "id": 11,
    "title": "Mapbox",
    "icon": faMapMarkedAlt,
    "description": "Custom integration with markers and cards"
  },
  {
    "id": 12,
    "title": "Design",
    "icon": faPenFancy,
    "description": "Crafted by professional UI/UX designers"
  }
];
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";
import { Table } from "react-bootstrap";

const SupplierInfo = ({ handleDetachSupplier, selectedSupplier }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <Table striped responsive>
            <tbody>
              <td>{selectedSupplier.companyName}</td>
              <tr>
                <td
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      fontWeight: "lighter",
                    }}
                  />
                </td>
              </tr>
              <td>
                <Button
                  variant="text"
                  startIcon={<FontAwesomeIcon icon={faTimes} />}
                  color="error"
                  onClick={handleDetachSupplier}
                  sx={{ fontSize: "12px" }}
                >
                  Detach
                </Button>
              </td>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SupplierInfo;

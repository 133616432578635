import {
  Input,
  InputLabel,
  FormGroup,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  Typography,
  Divider,
  AccordionDetails,
  Container,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import clientAdapter from "../../lib/clientAdapter";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CouponsView = ({ onClickBack, cupon }) => {
  const [couponNumber, setCouponNumber] = useState("");
  const [description, setDescription] = useState("");
  const [couponOption, setCouponOption] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [validityDate, setValidityDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [check, setCheck] = useState(false);
  const [onetime, setOneTime] = useState(false);
  const [data, setData] = useState("");
  const [couponData, setCouponData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState(false);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  const location = useLocation();

  const createCoupon = async () => {
    const data = {
      couponNumber,
      description,
      couponOption,
      value,
      startDate,
      validityDate,
      onetime,
    };
    if (customerId) {
      data.customerId = customerId;
    }
    try {
      const res = await clientAdapter.createCoupon(data);
      if (!res?.statusCode) {
        setData(res);
        setSnackBar({
          ...snackBar,
          open: true,
          severity: "success",
          message: "Coupon created successfully",
        });
        setTimeout(() => onClickBack(), 2000);
      } else {
        throw new Error(res?.message?.message[0] || "Error creating coupon");
      }
    } catch (error) {
      setSnackBar({
        ...snackBar,
        open: true,
        severity: "error",
        message: error?.message || "Error creating coupon",
      });
    }
  };

  const getCouponDetails = async () => {
    const queryParameters = new URLSearchParams(location.search);
    const id = queryParameters.get("id");
    if (id) {
      const CouponRes = await clientAdapter.getCouponById(id);
      setCouponData(CouponRes);
    }
  };
  const getCustomers = async (name) => {
    const response = await clientAdapter.getCustomerDataByName(1, 10, name);
    if (response) {
      setCustomers(response?.customers);
    }
  };

  useEffect(() => {
    getCouponDetails();
  }, []);

  const handleSnackbarClose = () => {
    setSnackBar({ ...snackBar, open: false });
  };

  const handleChange = () => {
    setCheck(!check);
    setOneTime(!check);
  };

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <ArrowBackIcon style={{ cursor: "pointer" }} onClick={onClickBack} />
        <Typography ml={2} fontSize={22} fontWeight={"bold"}>
          {cupon.isEditing ? "Edit Cupon" : "Create Cupon"}
        </Typography>
      </Box>
      <Accordion expanded>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Coupon Information</Typography>
        </AccordionSummary>
        <Divider></Divider>
        <AccordionDetails>
          <Container
            style={{
              backgroundColor: "white",
              padding: "25px",
              paddingTop: "0px",
              borderRadius: "3px",
            }}
          >
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "red",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Coupon Number :
                <Input
                  disableUnderline={true}
                  required
                  size="small"
                  style={{
                    display: "flex",
                    border: "1px solid Gray",
                    width: "80%",
                    padding: "2px",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  value={couponData.couponNumber}
                  id="couponNumber"
                  onChange={(e) => setCouponNumber(e.target.value)}
                ></Input>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "black",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Description :
                <Input
                  disableUnderline={true}
                  required
                  multiline={true}
                  rows={4}
                  size="small"
                  style={{
                    display: "flex",
                    border: "1px solid Gray",
                    width: "80%",
                    padding: "2px",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></Input>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "red",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Select Price Value Or
                <br /> Discount % :
                <Select
                  IconComponent={false}
                  variant="standard"
                  disableUnderline={true}
                  style={{
                    width: "80%",
                    border: "1px solid Gray",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  type="enum"
                  id="discount"
                  value={couponOption}
                  onChange={(e) => setCouponOption(e.target.value)}
                >
                  <MenuItem value="percentage" selected>
                    Percentage Discount
                  </MenuItem>
                  <MenuItem value="pricevalue">Price Value</MenuItem>
                </Select>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "red",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Value :
                <Input
                  disableUnderline={true}
                  required
                  size="small"
                  style={{
                    display: "flex",
                    border: "1px solid Gray",
                    width: "80%",
                    padding: "2px",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  id="value"
                  onChange={(e) => setValue(e.target.value)}
                ></Input>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "red",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Start Date :
                <Input
                  type="date"
                  disableUnderline={true}
                  required
                  size="small"
                  style={{
                    display: "flex",
                    border: "1px solid Gray",
                    width: "80%",
                    padding: "2px",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  id="startDate"
                  onChange={(e) => setStartDate(e.target.value)}
                ></Input>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "red",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Valid Upto :
                <Input
                  disableUnderline={true}
                  required
                  size="small"
                  style={{
                    display: "flex",
                    border: "1px solid Gray",
                    width: "80%",
                    padding: "2px",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  id="validity"
                  onChange={(e) => setValidityDate(e.target.value)}
                  type="date"
                ></Input>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "black",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Onetime Coupon :
                <Checkbox
                  checked={check}
                  value={check}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  style={{ left: "-77%" }}
                />
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "black",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Coupon Log :
                <span>
                  <ul>
                    <li>{data.logDate}</li>
                    <li>{data.logMessage}</li>
                    <li>{data.transactionAmount}</li>
                  </ul>
                </span>
              </InputLabel>
            </FormGroup>
            <FormGroup
              style={{
                display: "inline-block",
                width: "100%",
                fontFamily: "Russo One, sans-serif",
                marginTop: "10px",
                position: "relative",
              }}
            >
              <InputLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  color: "black",
                  fontFamily: "Russo One, sans-serif",
                  width: "100%",
                }}
              >
                Customer Name :
                <Input
                  disableUnderline={true}
                  required
                  size="small"
                  style={{
                    display: "flex",
                    border: "1px solid Gray",
                    width: "80%",
                    padding: "2px",
                    borderRadius: "3px",
                    backgroundColor: "white",
                  }}
                  id="customerName"
                  value={customerName}
                  onChange={(e) => {
                    getCustomers(e.target.value);
                    setCustomerName(e.target.value);
                    setCustomerSelected(!customerSelected);
                  }}
                ></Input>
              </InputLabel>
              {customers && customerSelected && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%", // Place the list below the input
                    left: "20%",
                    width: "40%", // Match the input width
                    backgroundColor: "white",
                    padding: "5px",
                    maxHeight: "150px", // Set the maximum height of the list
                    overflowY: "auto", // Add a scrollbar if the list exceeds the height
                    border: "1px solid Gray",
                    borderRadius: "3px",
                    zIndex: 9999,
                    cursor: "pointer",
                  }}
                >
                  {customers &&
                    customers.map((eachCustomer) => (
                      <div
                        style={{ backgroundColor: "white" }}
                        onClick={() => {
                          setCustomerName(eachCustomer?.person?.firstName);
                          setCustomerId(parseInt(eachCustomer?.personId));
                          setCustomerSelected(!customerSelected);
                        }}
                      >
                        <p> {eachCustomer.person.firstName}</p>
                        <hr />
                      </div>
                    ))}
                </div>
              )}
            </FormGroup>
            <Button
              style={{
                color: "white",
                borderRadius: "4px",
                backgroundColor: "black",
                border: "0",
                right: "-95%",
                marginTop: "20px",
              }}
              onClick={() => createCoupon()}
            >
              Submit
            </Button>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CouponsView;

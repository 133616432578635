import React, { useEffect, useState } from "react";
import ReportSkeletonLoader from "../../components/loader/reportSkeletonLoader";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import clientAdapter from "../../lib/clientAdapter";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Table } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment-timezone";
import PrintIcon from "@mui/icons-material/Print";

const DetailedSaleReport = ({ dateRange, onClickBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportRes, setReportRes] = useState(null);
  const [boxData, setBoxData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const inputFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
  const [errModal, setErrModal] = useState({
    open: false,
    message: "",
  });

  const header = [
    { title: "Sale Id", id: 1 },
    { title: "Date", id: 2 },
    { title: "Items Purchased", id: 3 },
    { title: "Customer Name", id: 4 },
    { title: "Frequency	", id: 5 },
    { title: "Total", id: 6 },
    { title: "Payment Type", id: 7 },
    { title: "Comments", id: 8 },
  ];
  const subHeader = [
    { title: "Name", id: 1 },
    { title: "Category", id: 2 },
    { title: "Service By", id: 3 },
    { title: "Current Selling Price", id: 4 },
    { title: "Quantity Purchased	", id: 5 },
    { title: "Subtotal", id: 6 },
    { title: "Tax", id: 7 },
    { title: "Total", id: 8 },
    { title: "Discount (%)", id: 9 },
  ];

  const getReport = async () => {
    setIsLoading(true);
    try {
      const res = await clientAdapter.getSaleReport(
        moment(dateRange.startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        moment(dateRange.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      );
      if (res.summary) {
        setReportRes(res);
      }

      if (res.boxes) {
        updateBoxData(res.boxes);
      }

      if (res.statusCode >= 400) {
        setErrModal({
          ...errModal,
          open: true,
          message: res.message,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateBoxData = (boxData) => {
    setBoxData([
      {
        id: 1,
        title: "Gross Total",
        subtitle: "(With Redemption)",
        value: `Rs.${boxData.fullgrossTotal.toFixed(2)}`,
      },
      {
        id: 2,
        title: "Gross Total",
        subtitle: "(Exc. Redemption)",
        value: `Rs.${boxData.grossTotal.toFixed(2)}`,
      },
      {
        id: 3,
        title: "Gross Service Sale",
        subtitle: "(Exc. Redemption)",
        value: `Rs.${boxData.serviceTotal.toFixed(2)}`,
      },
      {
        id: 4,
        title: "Gross Retail Sale",
        subtitle: "(Exc. Redemption)",
        value: `Rs.${boxData.retailTotal.toFixed(2)}`,
      },
      {
        id: 5,
        title: "Cash",
        subtitle: "",
        value: `Rs.${boxData.cashTotal.toFixed(2)}`,
      },
      {
        id: 6,
        title: "Credit / Debit Card",
        subtitle: "",
        value: `Rs.${boxData.cardTotal.toFixed(2)}`,
      },
      {
        id: 7,
        title: "Paytm",
        subtitle: "",
        value: `Rs.${boxData.paytmTotal.toFixed(2)}`,
      },
      {
        id: 8,
        title: "PhonePe",
        subtitle: "",
        value: `Rs.${boxData.phonepeTotal.toFixed(2)}`,
      },
      {
        id: 9,
        title: "Google Pay",
        subtitle: "",
        value: `Rs.${boxData.googlePayTotal.toFixed(2)}`,
      },
      {
        id: 10,
        title: "Deal Site Earnings",
        subtitle: "",
        value: `Rs.${boxData.dealSitesTotal.toFixed(2)}`,
      },
      {
        id: 11,
        title: "Family Card Redemption",
        subtitle: "",
        value: `Rs.${boxData.familyCardTotal.toFixed(2)}`,
      },
      {
        id: 12,
        title: "Gift Card Redemption",
        subtitle: "",
        value: `Rs.${boxData.giftCardTotal.toFixed(2)}`,
      },
      {
        id: 13,
        title: "Points Redemption",
        subtitle: "",
        value: `Rs.${boxData.pointsTotal.toFixed(2)}`,
      },
      {
        id: 14,
        title: "Coupon Redemption",
        subtitle: "",
        value: `Rs.${boxData.couponsTotal.toFixed(2)}`,
      },
      {
        id: 15,
        title: "Male Walkins",
        subtitle: "",
        value: boxData.maleTotal,
      },
      {
        id: 16,
        title: "Female Walkins",
        subtitle: "",
        value: boxData.femaleTotal,
      },
    ]);
  };

  useEffect(() => {
    getReport();
  }, [dateRange]);

  useEffect(() => {
    const initialExpandedRows = {};
    reportRes?.summary?.forEach((_, index) => {
      initialExpandedRows[index] = false; // Initialize all rows as collapsed
    });
    setExpandedRows(initialExpandedRows);
  }, [reportRes]);

  const handleExpandAll = () => {
    const allExpanded = Object.values(expandedRows).every((row) => row);
    const updatedExpandedRows = {};

    for (const key in expandedRows) {
      updatedExpandedRows[key] = !allExpanded;
    }

    setExpandedRows(updatedExpandedRows);
  };

  const handleExpand = (index) => {
    setExpandedRows((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const renderExpandIcon = (index) => {
    const allExpanded = Object.values(expandedRows).every((row) => row);
    return (
      <IconButton onClick={() => handleExpand(index)}>
        {allExpanded ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
    );
  };

  const handleErrModalClose = () => {
    setErrModal({ ...errModal, open: false });
  };

  return (
    <div>
      {isLoading ? (
        <ReportSkeletonLoader />
      ) : (
        <>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={onClickBack}
              />
              <Typography ml={2} fontSize={20} fontWeight={"bold"}>
                Detailed Sales Report{" "}
                <Typography variant="span" fontSize={14}>
                  {" "}
                  {`(${moment(dateRange.startDate).format("DD-MM-YYYY")} -
                ${moment(dateRange.endDate).format("DD-MM-YYYY")})`}
                </Typography>
              </Typography>
            </Box>
            <Grid mt={1} container spacing={2}>
              {boxData.map((b, x) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={x}>
                  <Box
                    px={2}
                    py={2}
                    height={100}
                    sx={{
                      width: "100%",
                      background: "lightBlue",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography color={"#111"} fontWeight={"bold"}>
                      {b?.value}
                    </Typography>
                    <Typography fontSize={14} color={"#111"}>
                      {b.title}
                    </Typography>
                    <Typography fontSize={14} color={"#111"}>
                      {b.subtitle}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={2}>
            <Table aria-label="simple table" bordered>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <IconButton onClick={handleExpandAll}>
                      {renderExpandIcon("header")}
                    </IconButton>
                  </TableCell>
                  {header.map((h) => (
                    <TableCell scope="col" key={h.id}>
                      {h.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportRes?.summary?.length ? (
                  <>
                    {reportRes?.summary?.map((i, x) => (
                      <React.Fragment key={x}>
                        <TableRow key={x}>
                          <TableCell>
                            <IconButton onClick={() => handleExpand(x)}>
                              {expandedRows[x] ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <PrintIcon
                              color="primary"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `/sales/receipt?saleId=${i?.sale_id}`,
                                  "_blank"
                                )
                              }
                            />
                            {i?.sale_id}
                          </TableCell>
                          <TableCell>
                            {moment(
                              moment.utc(i?.sale_time, inputFormat)
                            ).format("DD-MM-YYYY hh:mm A")}
                          </TableCell>
                          <TableCell>{i?.items_purchased}</TableCell>
                          <TableCell>{i?.customer_name}</TableCell>
                          <TableCell>{i.freq}</TableCell>
                          <TableCell>{Number(i?.total)?.toFixed(2)}</TableCell>
                          <TableCell>
                            {i?.payment_type?.split("<br />").map((i, x) => (
                              <React.Fragment key={x}>
                                {i}
                                <br />
                              </React.Fragment>
                            ))}
                          </TableCell>
                          <TableCell>{i?.comment}</TableCell>
                        </TableRow>
                        {expandedRows[x] && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Table bordered>
                                <TableHead>
                                  <TableRow>
                                    {subHeader.map((he) => (
                                      <TableCell scope="col" key={he.id}>
                                        {he.title}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {reportRes?.saleDetail
                                    ?.filter((it) => it.sale_id === i.sale_id)
                                    ?.map((sd, y) => (
                                      <TableRow key={y}>
                                        <TableCell>{sd?.item_name}</TableCell>
                                        <TableCell>{sd?.category}</TableCell>

                                        <TableCell>
                                          {sd?.technician_name}
                                        </TableCell>
                                        <TableCell>
                                          {Number(sd?.item_unit_price)?.toFixed(
                                            2
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {Number(sd?.quantity_purchased)}
                                        </TableCell>
                                        <TableCell>
                                          {Number(sd?.subtotal)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                          {Number(sd?.tax)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                          {Number(sd?.total)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                          {Number(
                                            sd?.discount_percent
                                          )?.toFixed(2)}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>No Data Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>

          <Dialog
            open={errModal.open}
            onClose={handleErrModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {errModal.message}
            </DialogTitle>
            <DialogActions  style={{ display: 'flex', justifyContent: 'center',paddingBottom:'15px' }}>
              <Button 
              style={{
                color: "white",
                borderRadius: "4px",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
              onClick={onClickBack}>Go Back</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default DetailedSaleReport;

import React, { useEffect, useState } from "react";
import ReportSkeletonLoader from "../../components/loader/reportSkeletonLoader";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PrintIcon from "@mui/icons-material/Print";

import clientAdapter from "../../lib/clientAdapter";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Table } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment-timezone";

const DetailedEmployeeReport = ({
  dateRange,
  onClickBack,
  employeeIds,
  employees,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportRes, setReportRes] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [addIcon, setAddIcon] = useState(true);
  const [amountTotal, setAmountTotal] = useState({
    netTotalServices: 0,
    netTotalProducts: 0,
    grossTotalServices: 0,
    grossTotalProducts: 0,
    totalRedemptions: 0,
  });

  const employeeMap = employees.map((employee) => ({
    id: employee.employeeId,
    name: employee.person?.firstName,
  }));

  const [errModal, setErrModal] = useState({
    open: false,
    message: "",
  });

  const inputFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";

  const header = [
    { title: "Sale Id", id: 1 },
    { title: "Date", id: 2 },
    { title: "Employee Name", id: 3 },
    { title: "Items Purchased", id: 4 },
    { title: "Employee Total (Without Tax)", id: 6 },
    { title: "Payment Type", id: 7 },
    { title: "Comments", id: 8 },
  ];

  const subHeader = [
    { title: "Name", id: 1 },
    { title: "Current Selling Price", id: 4 },
    { title: "Quantity Purchased", id: 5 },
    { title: "Discount", id: 9 },
  ];

  const getReport = async () => {
    setIsLoading(true);
    try {
      const res = await clientAdapter.getEmployeeReport({
        startDate: moment(dateRange.startDate)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(dateRange.endDate)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        employeeIds,
      });

      if (res) {
        setReportRes(res.sales);
      } else {
        setReportRes(null);
      }

      if (res) {
        setAmountTotal({
          netTotalServices: res.netTotalServices,
          netTotalProducts: res.netTotalProducts,
          grossTotalServices: res.grossTotalServices,
          grossTotalProducts: res.grossTotalProducts,
          totalRedemptions: res.totalRedemptions,
        });
      }

      if (res.statusCode >= 400) {
        setErrModal({
          ...errModal,
          open: true,
          message: res.message,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setReportRes(null);
    }
  };

  const handleErrModalClose = () => {
    setErrModal({ ...errModal, open: false });
  };

  useEffect(() => {
    getReport();
  }, [dateRange]);

  const handleExpand = (index) => {
    setAddIcon(!addIcon);
    setExpandedRows((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  return (
    <div>
      {isLoading ? (
        <ReportSkeletonLoader />
      ) : (
        <>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={onClickBack}
              />
              <Typography ml={2} fontSize={20} fontWeight={"bold"}>
                Detailed Employee Report{" "}
                <Typography variant="span" fontSize={14}>
                  {" "}
                  {`(${moment(dateRange.startDate).format("DD-MM-YYYY")} -
                ${moment(dateRange.endDate).format("DD-MM-YYYY")})`}
                </Typography>
              </Typography>
            </Box>
            <Grid mt={1} container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box
                  px={2}
                  py={2}
                  sx={{
                    width: "100%",
                    background: "lightBlue",
                    borderRadius: "4px",
                  }}
                >
                  <Typography fontSize={14} color={"#111"}>
                    Gross Service Total: Rs.{amountTotal.grossTotalServices}
                  </Typography>
                  <Typography fontSize={14} color={"#111"}>
                    Net Service Sale: Rs.{amountTotal.netTotalServices}
                  </Typography>
                  <Typography fontSize={14} color={"#111"}>
                    Gross Product Total: Rs.{amountTotal.grossTotalProducts}
                  </Typography>
                  <Typography fontSize={14} color={"#111"}>
                    Net Product Sale: Rs.{amountTotal.netTotalProducts}
                  </Typography>
                  <Typography fontSize={14} color={"#111"}>
                    Total redemption: Rs.{amountTotal.totalRedemptions}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Table aria-label="simple table" bordered>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleExpand("header")}>
                      {addIcon ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                  </TableCell>
                  {header.map((h) => (
                    <TableCell scope="col" key={h.id}>
                      {h.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportRes?.length ? (
                  <>
                    {reportRes?.map((i, x) => (
                      <React.Fragment key={x}>
                        <TableRow key={x}>
                          <TableCell>
                            <IconButton onClick={() => handleExpand(x)}>
                              {expandedRows[x] ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <PrintIcon
                              color="primary"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `/sales/receipt?saleId=${i?.id}`,
                                  "_blank"
                                )
                              }
                            />
                            {i?.id || "N/A"}
                          </TableCell>
                          <TableCell>
                            {moment(i?.saleTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            ) || "N/A"}
                          </TableCell>
                          <TableCell>
                            {employeeMap.find(
                              (emp) =>
                                emp.id === i?.saleItems[0].serviceEmployeeId
                            )?.name || "N/A"}
                          </TableCell>

                          <TableCell>
                            {i?.saleItems
                              .map((item) => item.item.name)
                              .join(", ")}
                          </TableCell>

                          <TableCell>
                            {Number(i?.saleNetTotal) -
                              Number(i?.saleNetTotal) * 0.18}
                          </TableCell>
                          <TableCell>
                            {i?.paymentType?.split("<br />")[0] || "N/A"}
                          </TableCell>
                          <TableCell>{i?.comment || "N/A"}</TableCell>
                        </TableRow>
                        {expandedRows[x] && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Table bordered>
                                <TableHead>
                                  <TableRow>
                                    {subHeader.map((he) => (
                                      <TableCell scope="col" key={he.id}>
                                        {he.title}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {reportRes?.detail
                                    ?.filter((it) => it.sale_id === i.sale_id)
                                    ?.map((sd, y) => (
                                      <TableRow key={y}>
                                        <TableCell>
                                          {sd?.name || "N/A"}
                                        </TableCell>
                                        <TableCell>
                                          {Number(sd?.item_unit_price)?.toFixed(
                                            2
                                          ) || "N/A"}
                                        </TableCell>
                                        <TableCell>
                                          {Number(
                                            sd?.quantity_purchased
                                          ).toFixed() || "N/A"}
                                        </TableCell>
                                        <TableCell>
                                          {Number(
                                            sd?.discount_percent
                                          )?.toFixed(2) || "N/A"}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
      <Dialog
        open={errModal.open}
        onClose={handleErrModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{errModal.message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleErrModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DetailedEmployeeReport;
